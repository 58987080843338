import { OwnUpBox } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { PageTitle, PrimaryText } from '../components/typography';

const Section = styled(OwnUpBox)`
  margin-bottom: 16px;
`;

// eslint-disable-next-line max-lines-per-function
export const LenderPartners = () => (
  <Layout>
    <PageWrapper>
      <ContentWrapper>
        <PageTitle variant="title">Own Up Lender Partners</PageTitle>
        <Section>
          <PrimaryText>
            In states where we’re not licensed, we will provide you with a list of our lender
            partners who are licensed in your state, and specialize in your scenario.
          </PrimaryText>
        </Section>
        <Section>
          <PrimaryText>
            Own Up will not be able to pre qualify you for a loan, but will provide you with a
            choice of Lenders where available, along with their information if you would like to
            proceed.
          </PrimaryText>
        </Section>
        <Section>
          <PrimaryText>
            There is no obligation for you to continue, and we will not collect any fees from the
            lenders presented to you because we believe in helping Americans shop for a fair deal
            for their mortgage.
          </PrimaryText>
        </Section>
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default LenderPartners;
